.bounce:hover{
animation: bounceFront ease-in-out 1s linear;
}
@keyframes bounceFront {
    0%{
        transform: scaleX(0.5,0.5)
    }
    50%{
        transform: scaleX(2,2)
    }
    100%{
        transform: scaleX(0.5,0.5)
    }
}
.popUp {
    animation: slowMotion 1s ease-in-out;
}
@keyframes slowMotion {
    from{
        margin-top:-300px
    }
    to{
        margin-top:30px
    }
    
}
.trans{
    animation:fadeInOut 2.8s ease-in-out
}
@keyframes fadeInOut {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
    
}